<template>
  <div id="app">
    <header>
      <nav class="navbar navbar-expand-lg navbar-dark" aria-label="Offcanvas navbar large">
        <div class="container">
          <a class="navbar-brand d-block d-lg-none" href="index.html"><img src="/img/logotype_big.svg" alt="Limanode" /></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbar2Label">
                <a class="navbar-brand" href="index.html"><img src="/img/logo.svg" alt="Limanode" /></a>
              </h5>
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-center flex-grow-1 pe-3">
                <li class="nav-item" :class="{ active: route.name === 'Home' }"><a class="nav-link" href="/">Home</a></li>
                <li class="nav-item" :class="{ active: route.name === 'About' }"><a class="nav-link" href="/about/">About us</a></li>
                <li class="nav-item"><a class="nav-link" href="/#exchanges">Exchanges</a></li>
                <li class="d-none d-lg-block"><a class="nav-link" href="/"><img src="/img/logotype_big.svg" width="170"></a></li>
                <li class="nav-item" :class="{ active: route.name === 'FAQ' }"><a class="nav-link" href="/faq/">FAQ</a></li>
                <li class="nav-item" :class="{ active: route.name === 'Rules' }"><a class="nav-link" href="/rules/">Rules</a></li>
                <li class="nav-item" :class="{ active: route.name === 'Rules' }"><a class="nav-link" href="https://www.youtube.com/@coinviewingx" target="_blank"><img src="/img/youtube.svg" class="youtube" width="40" height="40" alt="Youtube" /></a></li>
                <!-- <li class="nav-item "><a class="nav-link" href="contacts.html">Contacts</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>


    <!-- Здесь будет отображаться текущая страница -->
    <router-view></router-view>


    <footer class="footer mt-auto py-3">
      <div class="container">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start py-5">
          <div class="col-lg-5 mb-3">
            <div class="pe-0 pe-lg-5">
              <a href="/" class="d-flex align-items-center mb-3 justify-content-center justify-content-lg-start">
                <img src="/img/logotype_big.svg" width="170">
              </a>
              <p class="text-ln-main mw-copy">
                © 2024, coinviewingx.com
                — cryptocurrency exchange monitoring
              </p>
            </div>
          </div>
          <div class="col-lg-2 mb-3">
            <h5>Useful</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2">
                <a href="/about/" class="nav-link p-0">About Us</a>
              </li>
              <li class="nav-item mb-2">
                <a href="/rules/" class="nav-link p-0">Rules</a>
              </li>
              <li class="nav-item mb-2">
                <a href="/faq/" class="nav-link p-0">FAQ</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 mb-3">
            <h5>Additionally</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2">
                <a href="/privacy/" class="nav-link p-0">Privacy policy</a>
              </li>
              <!-- <li class="nav-item mb-2">
                <a href="contacts.html" class="nav-link p-0">Contact Us</a>
              </li> -->
            </ul>
          </div>
          <div class="col-lg-2 mb-3">
            <h5>Contacts</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2">
                <a class="nav-link p-0" href="mailto:coinviewingx@gmail.com">coinviewingx@gmail.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>


<script>
import { useRoute } from 'vue-router';
export default {
  name: 'App',
  setup() {
    const route = useRoute();
    console.log('Current route name:', route.name)

    return { route };
  }
};
</script>


<style>
  /* Импортируем стили внутри компонента */
  @import 'assets/css/fonts.css';
  @import 'assets/css/animate.min.css';
  @import 'assets/css/dataTables.bootstrap5.min.css';
  @import 'assets/css/bootstrap.min.css';
  @import 'assets/css/styles.css';

  /* Стили для навигации */
  nav {
    margin-bottom: 20px;
  }
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  nav ul li {
    display: inline;
    margin-right: 15px;
  }

  .youtube {
    margin-top: 5px;
    width: 50px;
    fill: #F2F2F2;
    transform: scale(1.5);
  }

  .youtube:hover {
    transform: scale(1.8);
    transition: 0.5s;
/*    margin-left: 10px;*/
  }
</style>
