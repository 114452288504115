<template>
  <section class="sec-def markets">
    <div class="container">
      <div>
        <div class="row">
          <div class="section-title">
            <h2>{{ selectedButton }} Markets</h2>
          </div>
        </div>

        <div class="table-responsive mt-4 d-flex justify-content-center">
          <table v-if="data" class="table table-hover align-middle mb-0">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col"> </th>
                <th scope="col">Exchange</th>
                <th scope="col" @click="toggleSort">
                  <span :class="priceSortDirection === 'asc' ? 'arrow-up' : 'arrow-down'"></span>
                  Price
                  <span :class="priceSortDirection === 'asc' ? 'arrow-up' : 'arrow-down'"></span>
                </th>
                <th scope="col">24h Change</th>
                <th scope="col">24h Volume</th>
                <th scope="col">1m Volume Change</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in visibleData" :key="item.id" @click="goToExchange(item)">
                <td>{{ index + 1 }}</td>
                <td><img v-if="item.exchange_image_url" :src="'https://coinvievingx.com' + item.exchange_image_url" width="30" height="30" /></td>
                <td>{{ item.exchange_name }}</td>
                <td>$ {{ Number(item.price).toFixed(4) }}</td>
                <td>{{ Number(item.change_24h).toFixed(2) }} %</td>
                <td>$ {{ Number(item.volume).toFixed(2) }}</td>
                <td>{{ Number(item.volume_change).toFixed(2) }} %</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="div-show-more">
          <button v-if="visibleCount < data.length" @click="loadMoreExchanges" class="btn btn-primary mt-3 mx-auto btn-show-more">
            Show more exchanges
          </button>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
  props: {
    selectedButton: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const data = ref([]);
    const visibleCount = ref(10);  // Количество видимых бирж
    const priceSortDirection = ref('asc'); // Направление сортировки по цене: 'asc' или 'desc'

    // Функция для получения данных с API
    const fetchData = async () => {
      try {
        const response = await fetch(`https://coinvievingx.com/api/exchanges/${props.selectedButton}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          // Преобразуем ответ в JSON и сохраняем в data
          const result = await response.json();

          console.log('Fetched data:', result);

          data.value = result;  // если API возвращает данные в корне ответа
        } else {
          console.error('Error fetching data:', response.status);
        }
      } catch (err) {
        console.error('Error:', err);
      }
    };

    // Сортировка данных по цене
    const sortedData = computed(() => {
      const sorted = [...data.value];
      return sorted.sort((a, b) => {
        if (priceSortDirection.value === 'asc') {
          return a.price - b.price;
        } else {
          return b.price - a.price;
        }
      });
    });

    // Выборка видимых данных
    const visibleData = computed(() => {
      return sortedData.value.slice(0, visibleCount.value);
    });

    // Функция для загрузки дополнительных бирж
    const loadMoreExchanges = () => {
      visibleCount.value += 10;  // увеличиваем количество видимых бирж на 10
      scrollToBottom();  // вызываем прокрутку
    };

    // Прокрутка таблицы вниз
    const scrollToBottom = () => {
      const element = document.getElementById("next-table-element");
      const rect = element.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      window.scrollTo({
          top: scrollTop + rect.top - window.innerHeight + rect.height,
          behavior: "smooth"
      });
    };

    const goToExchange = (item) => {
      console.log(item.exchange_href)
      if (item.exchange_href) window.open(item.exchange_href, '_blank');
    };

    // Изменение направления сортировки по цене
    const toggleSort = () => {
      priceSortDirection.value = priceSortDirection.value === 'asc' ? 'desc' : 'asc';
    };

    // Получение данных при монтировании компонента
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      visibleData,
      visibleCount,
      priceSortDirection,
      goToExchange,
      loadMoreExchanges,
      toggleSort
    };
  }
};
</script>

<style scoped>
.arrow-up::before {
  content: "↑";
}

.arrow-down::before {
  content: "↓";
}
.table {
  width: 100%;
}

.div-show-more {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-show-more {
  margin: 0 auto;
}
</style>