import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Faq from '../views/Faq.vue';
import Rules from '../views/Rules.vue';
import Privacy from '../views/Privacy.vue';

// Создание маршрутов
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Сoinviewingx - The best crypto courses on all crypto exchanges' },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'Сoinviewingx - About' },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq,
    meta: { title: 'Сoinviewingx - FAQ' },
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
    meta: { title: 'Сoinviewingx - Rules' },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: { title: 'Сoinviewingx - Privacy' },
  }
];

// Создание и экспорт роутера
const router = createRouter({
  history: createWebHistory(''),
  routes
});

export default router;
