<template>
  <div>
    <section class="sec-def pt-5">
      <div class="container">
        <div class="row">
          <div class="section-title text-center">
            <h1>privacy <span>policy</span>
            </h1>
          </div>
        </div>
        <div class="article mt-4">
          <div class="row">
            <div class="col-lg-12">
              <p class="description-text">
                <span class="desc-title mb-4">What Information Do We Collect?</span>
                <span class="desc-text">
                  Сoinviewingx (“Сoinviewingx”, “we” or “us”) collects (a) the e-mail addresses of those who communicate with us via email; (b) aggregate information concerning what
                  pages users access or visit; (c) information given by the user (such as survey information and/or site registrations); and (d) information related to your use of
                  the Website and/or the mobile application, including IP address, Device ID, geographic location, and date and time of your request; (e) any other information
                  that you might provide voluntarily. We might also collect your email address and your Wallet ID if you decide to participate in one of our campaigns.
                  <br><br>
                  We do not knowingly collect information from children under the age of 18. By using the Сoinviewingx service, you are representing that you are at least
                  18 years old.
                </span>
                <span class="desc-title mb-4">How Do We Use the Information?</span>
                <span class="desc-text">
                  Сoinviewingx uses collected information for the following purposes:
                  <br><br>
                  To fulfill a contract or take steps linked to a contract such as processing your registration on our Website or sending you information about changes to our
                  terms or policies;
                  <br><br>
                  Where it is necessary for purposes which are in Сoinviewingx’s legitimate interests such as (a) to provide the information or content you have requested; (b) to
                  contact you about our programs, products, features or services; (c) to improve our services and for internal business purposes such as identification and
                  authentication or customer service, portfolio tracking and user preference syncing between devices; (d) to ensure the security of our Website, by trying to
                  prevent unauthorized or malicious activities; (e) to enforce compliance with our Terms of Use and other policies; (f) to help other organizations
                  (such as copyright owners) to enforce their rights; and (g) to tailor content, advertisements, and offers for you or for other purposes disclosed at the
                  time of collection.
                  <br><br>
                  If you do not wish to receive marketing information about our programs, products, features or services, you can opt-out of marketing communications.
                  <br><br>
                  Where you give us consent, such as (a) where you ask us to send marketing information to you via a medium where we need your consent, including alerts via
                  mobile push notifications; (b) where you give us consent to place cookies and to use similar technologies; and (c) on other occasions where we ask you for
                  consent, for any other purpose that might arise.
                  <br><br>
                  Where we are legally required to do so. We may also provide access to your personally identifiable information when legally required to do so, to cooperate
                  with police investigations or other legal proceedings, to protect against misuse or unauthorized use of our Website, to limit our legal liability and protect our
                  rights, or to protect the rights, property or safety of visitors of the Website or the. In those instances, the information is provided only for that purpose.
                </span>
                <span class="desc-title mb-4">How Do We Share Your Information?</span>
                <span class="desc-text">
                  We do not sell your personal data to other organizations for commercial purposes. We only share your personal information to provide products or services
                  you’ve requested, when we have your permission, or under the following circumstances:
                  <br><br>
                  When it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving
                  potential threats to the physical safety of any person, violations of Terms of Service, available at https://Сoinviewingx.com/privacy/ or as otherwise required by law.
                  <br><br>
                  We provide personal information to trusted businesses or persons for the sole purpose of processing personally identifying information on our behalf and
                  provide us with services. When this is done, it is subject to agreements that oblige those parties to process such information only on our instructions and in
                  compliance with this Privacy Policy and appropriate confidentiality and security measures.
                  <br><br>
                  We provide such information to third parties who have entered into non-disclosure agreements with us.
                  <br><br>
                  We provide such information to a company controlled by, or under common control with, Сoinviewingx for any purpose permitted by this Privacy Policy.
                  <br><br>
                  We may aggregate, anonymize, and publish data for statistical and research purposes only. For example, we may compile and share information related to the
                  popularity of certain products tracked by users. In any such instance, the information will not be able to be traced back to any individual.
                </span>
                <span class="desc-title mb-4">Events</span>
                <span class="desc-text">
                  In connection with events, Сoinviewingx collects your a) prefix, b) name, c) email address, d) company name, e) role, f) job level, g) industry, h) state and country,
                  i) LinkedIn profile, j) card payment details and k) billing address. We collect this data from you to facilitate the ticketing process, to engage in internal analytics,
                  for internal CMC marketing purposes and for external marketing by a third party, the event sponsors. The following data is shared with event sponsors:
                  your a) name, b) title, c) company and d) email address. The following data is shared with the event platform: a) all details that are inserted into the registration
                  form by you, the event attendee, and b) your payment details.
                </span>
                <span class="desc-title mb-4">Security</span>
                <span class="desc-text">
                  We take precautions to ensure the security of your personal information. We ensure that our Website is protected by reasonable security measures afforded by
                  current technology, and that all our data hosts and servers are similarly protected by such security measures, including but not limited to firewalls. We strongly
                  advise you to change your password frequently and not to use the same password for different websites. We cannot protect, nor does this Privacy Policy
                  apply to, any information that you transmit to other users. You should never transmit personal or identifying information to other users.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-sdr mt-5">
      <div class="container">
        <div class="row">
          <div class="site-desc text-center pt-5">
            <h2><span>Live ETH, XRP, LTC to USDT</span> prices,<br>
              price charts, and market cap</h2>
            <p class="mt-5">Find out the real price of ETH, XRP, LTC in USDT by monitoring cryptocurrency exchanges from Сoinviewingx</p>
            <img src="/img/footer.svg" class="mt-5">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: 'Faq',
  components: {}
};
</script>