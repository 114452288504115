<template>
	<div>
		<section class="sec-def overflow-hidden">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-lg-5 order-2 order-lg-1">
						<div class="hero-start">
							<h1>Want to <span>buy Crypto</span>
								quickly and securely?
							</h1>
							<p class="my-4">Our monitoring of exchanges will help you choose the most favorable and reliable online
								exchange rate among all popular exchanges.</p>
							<a href="#exchanges" class="btn-def">List of exchanges <img src="/img/icons/arrow-right.svg"></a>
						</div>
					</div>
					<div class="col-lg-7 order-1 order-lg-2">
						<div class="hero-end text-center text-lg-end">
							<img src="/img/hero.svg" class="hero-nb">
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="exchanges" class="sec-def overflow-hidden">
			<div class="container">
				<div class="d-flex justify-content-start">
						<!-- Три кнопки в родительском компоненте -->
			    <button 
			      v-for="(button, index) in buttons" 
			      :key="index"
			      :class="{ selected: selectedButton === button }"
			      class="btn btn-primary mt-3 mx-3" 
			      @click="selectButton(button)"
			    >
			      {{ button }}
			    </button>
				</div>
			</div>
		</section>

		<TableComponent :key="selectedButton" :selectedButton="selectedButton" />

		<section class="sec-def graph">
			<div class="container">
				<div class="article">
					<div class="row">
						<div class="section-title">
							<h2 id="course">{{ price }}</h2>
							<p class="mt-1 wth-icon" id="procent">{{ percentChange }} %</p>
						</div>
					</div>
					<div id="next-table-element" class="row">
						<div class="graph-block">
							<canvas id="chart" ref="chartCanvas" height="280" width="1087"></canvas>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="sec-def about" id="about">
			<div class="container">
				<div class="row">
					<div class="col-lg-8">
						<div class="row">
							<div class="section-title">
								<h2>About {{ selectedButton }}</h2>
							</div>
						</div>
						<div class="article mt-4">
							<p v-if="selectedButton == 'ETH'">Ethereum is a decentralized blockchain platform that enables the creation of smart contracts and decentralized applications (dApps). It operates on its blockchain ledger, called the Ethereum network, and uses its native cryptocurrency, Ether (ETH), to facilitate transactions and power operations within the ecosystem. Ethereum supports a wide range of applications, making it a versatile platform for digital payments and beyond.</p>
							<p v-else-if="selectedButton == 'LTC'">LTC (Litecoin) is a decentralized cryptocurrency that provides a global payment solution via its blockchain network. It is designed to facilitate fast, secure, and low-cost digital transactions. Litecoin operates on its own blockchain ledger and uses LTC tokens to enable efficient digital payments.</p>
							<p v-else>Ripple is a privately held company that provides a global payments solution via it’s payment network
								called Ripple Network (also known as RippleNet). RippleNet is a payment network built on top of
								Ripple's consensus blockchain ledger, called XRP ledger (also known as XRPL) using XRP tokens to
								facilitate digital payments.</p>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="row">
							<div class="section-title">
								<h2>Stats</h2>
							</div>
						</div>
						<div class="article mt-4">
							<div class="article-cols">
								<div class="ac-col">
									<p>Market cap</p>
									<p v-if="selectedButton == 'ETH'">$228.5B</p>
									<p v-else-if="selectedButton == 'LTC'">$5.69B</p>
									<p v-else>$51.27B</p>
								</div>
								<div class="ac-col">
									<p>Circulating supply</p>
									<p v-if="selectedButton == 'ETH'">120,589,000 ETH</p>
									<p v-else-if="selectedButton == 'LTC'">73,537,000 LTC</p>
									<p v-else>55,960,868,753 XRP</p>
								</div>
								<div class="ac-col">
									<p>Max supply</p>
									<p v-if="selectedButton == 'ETH'">NO LIMIT</p>
									<p v-else-if="selectedButton == 'LTC'">84,000,000 LTC</p>
									<p v-else>84,000,000 LTC</p>
								</div>
								<div class="ac-col">
									<p>24h trading volume</p>
									<p v-if="selectedButton == 'ETH'">$8.97B</p>
									<p v-else-if="selectedButton == 'LTC'">$294M</p>
									<p v-else>$2.38B</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="bg-sdr mt-5">
			<div class="container">
				<div class="row">
					<div class="site-desc text-center pt-5">
						<h2><span>Live ETH, XRP, LTC to USDT</span> prices,<br>
							price charts, and market cap</h2>
						<p class="mt-5">Find out the real price of ETH, XRP, LTC in USDT by monitoring cryptocurrency exchanges from xrp-price</p>
						<img src="/img/footer.svg" class="mt-5">
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import TableComponent from '../components/TableComponent.vue';
import { ref, onMounted, watch  } from 'vue';
import { Chart } from 'chart.js';

export default {
	name: 'Home',
	components: {
		TableComponent
	},
	setup() {
    // Массив кнопок
    const buttons = ['ETH', 'XRP', 'LTC'];

		const selectedButton = ref(buttons[0]);

		const chartCanvas = ref(null); // ссылка на элемент canvas
		const price = ref(`1 ${selectedButton.value} = $1`); // начальная цена
		const percentChange = ref('+0.00'); // начальный процент
		let chartInstance = null; // Переменная для хранения экземпляра графика

    // Метод для выбора кнопки
    const selectButton = (button) => {
    	chartInstance.destroy();
      selectedButton.value = button;
    };

		// Функция для получения данных с API
		const fetchData = async () => {
			// Удаляем старый график, если он существует
			if (chartInstance) {
				chartInstance.destroy();
			}

			try {
				const response = await fetch(`https://coinvievingx.com/api/prices/${selectedButton.value}/`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				const data = await response.json();

				// Получаем последние данные для отображения текущей цены
				const latestData = data[0];
				price.value = `1 ${selectedButton.value} = $${Number(latestData.price).toFixed(2)}`;
				percentChange.value = Number(latestData.change_24h).toFixed(2); // изменение за 24 часа

				// Преобразуем данные для графика (время и цена)
				const labels = data.map(item => new Date(item.timestamp).toLocaleTimeString()); // временные метки
				const prices = data.map(item => parseFloat(item.price).toFixed(4)); // цена в числовом формате

				// Обновление графика с новыми данными
				updateChart(labels, prices);
			} catch (error) {
				console.error('Ошибка при загрузке данных:', error);
			}
		};

		// Функция для обновления графика
		const updateChart = (labels, data) => {
			// Создание нового графика
			chartInstance = new Chart(chartCanvas.value, {
				type: 'line',
				data: {
					labels: labels,
					datasets: [{
						label: `${selectedButton.value} Price`,
						data: data,
						borderColor: '#4BC0C0',
						backgroundColor: 'rgba(75, 192, 192, 0.2)',
						fill: true,
						tension: 0.4
					}]
				},
				options: {
					responsive: true,
					scales: {
						x: {
							title: {
								display: true,
								text: 'Time'
							},
							ticks: {
								autoSkip: true,
								maxTicksLimit: 10
							}
						},
						y: {
							title: {
								display: true,
								text: 'Price (USD)'
							},
							beginAtZero: false
						}
					}
				}
			});
		};

		// Запускаем fetchData при монтировании компонента
		onMounted(() => {
			fetchData();
		});

		watch(selectedButton, () => {

			chartCanvas.value = null; // ссылка на элемент canvas
			price.value = `1 ${selectedButton.value} = $1`; // начальная цена
			percentChange.value = '+0.00'; // начальный процент
      fetchData(); // Загружаем новые данные при смене кнопки
    });

		return {
			chartCanvas,
			price,
			percentChange,
      selectedButton,
      buttons,
      selectButton,
		};
	}
};
</script>

<style scoped>
/* Ваши стили для графика и компонентов */
.container {
	max-width: 1200px;
	margin: 0 auto;
}

.article {
	padding: 20px;
}

.section-title h2 {
	font-size: 2em;
	font-weight: bold;
}

.mt-1 {
	margin-top: 10px;
}

.graph-block {
	margin-top: 20px;
}

.selected {
  background-color: blue;
  color: white;
}

</style>
