<template>
  <div>
    <section class="sec-def pt-5">
      <div class="container">
        <div class="row">
          <div class="section-title text-center">
            <h1>Frequently Asked <span>Questions</span></h1>
          </div>
        </div>
        <div class="article mt-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="accordion">
                <!-- FAQ Items -->
                <div 
                  v-for="(item, index) in faqItems" 
                  :key="index" 
                  class="accordion-item"
                >
                  <h2 class="accordion-header" :id="'heading' + index">
                    <button
                      class="accordion-button gap-2"
                      type="button"
                      @click="toggleItem(index)"
                      :aria-expanded="activeItem === index"
                      :aria-controls="'collapse' + index"
                      :class="{ collapsed: activeItem !== index }"
                    >
                      <img src="/img/icons/question.svg" /> 
                      <span>{{ item.question }}</span>
                    </button>
                  </h2>
                  <div
                    :id="'collapse' + index"
                    class="accordion-collapse"
                    :class="{ collapse: activeItem !== index, show: activeItem === index }"
                  >
                    <div class="accordion-body">
                      {{ item.answer }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Faq',
  setup() {
    // Данные для FAQ
    const faqItems = [
      {
        question: 'How are the prices calculated for the various cryptocurrencies?',
        answer:
          'Please refer to the Market Data section of the methodology for detailed information on this topic.',
      },
      {
        question: 'What is "Market Capitalization" and how is it calculated?',
        answer:
          'Market Capitalization is one way to rank the relative size of a cryptocurrency. It is calculated by multiplying the Price by the Circulating Supply. Market Cap = Price X Circulating Supply.',
      },
      {
        question: 'What is the difference between "Circulating Supply", "Total Supply", and "Max Supply"?',
        answer:
          'Circulating Supply is the best approximation of the number of coins circulating in the market. Total Supply is the total amount of coins in existence right now. Max Supply is the best approximation of the maximum amount of coins that will ever exist.',
      },
      {
        question: 'Why is the Circulating Supply used in determining the market capitalization instead of Total Supply?',
        answer:
          'Circulating Supply is a better metric for determining market capitalization as it excludes coins that are locked, reserved, or not able to affect the price.',
      },
      {
        question: 'What is the difference between a "Coin" and a "Token" on the site?',
        answer:
          'A Coin is a cryptocurrency that operates independently. A Token depends on another cryptocurrency as a platform.',
      },
      {
        question: 'What is the criteria for a cryptocurrency or exchange to be listed on XRP-Price?',
        answer:
          'Please refer to the Listings Criteria section of the methodology for detailed information on this topic.',
      },
    ];

    // Текущее состояние открытого элемента
    const activeItem = ref(0);

    // Функция переключения состояния
    const toggleItem = (index) => {
      activeItem.value = activeItem.value === index ? null : index;
    };

    return {
      faqItems,
      activeItem,
      toggleItem,
    };
  },
};
</script>

<style>
.collapse {
  display: none;
}

.show {
  display: block;
}
.accordion-button {
  cursor: pointer;
}

</style>
