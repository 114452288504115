<template>
  <div>
    <section class="sec-def pt-5">
      <div class="container">
        <div class="row">
          <div class="section-title text-center">
            <h1>Terms of <span>Use</span>
            </h1>
          </div>
        </div>
        <div class="article mt-4">
          <div class="row">
            <div class="col-lg-12">
              <p class="description-text">
                <span class="desc-title mb-4">Introduction</span>
                <span class="desc-text">These coinviewingx.com Terms of Use (the &#34;Terms&#34;) govern your access to and use of our website and
                  mobile application (collectively, the &#34;Platform&#34;), andassociated content, features, software and
                  applications in the Platform (collectively, the &#34;Service&#34;). These Terms also include our Privacy
                  Policy, which islocated at https://coinviewingx.com/rules/.
                  <br><br>
                  The Platform and the Service is administered and maintained by coinviewingx. The terms &#34;we&#34;, &#34;us&#34; and &#34;our&#34; also refer to
                  coinviewingx. The term &#34;you&#34; refers to theperson visiting the Platform.
                  <br><br>
                  Please read these Terms carefully. They contain important information regarding your legal rights, including limitations
                  on coinviewingx&#39;s and certain thirdparties&#39; liability, disclaimers of warranties and a submission
                  to jurisdiction. Each time you access the Service by any means (for example, through the Internetor on a
                  mobile device), you irrevocably agree to comply with the version of the Terms posted on the Platform at the
                  time you access the Platform. You may notuse the Platform or the Service if you do not agree to these
                  Terms.</span>
                <br>
                <span class="desc-title mb-4">Eligibility</span>
                <span class="desc-text">To access or use the Service, you must be at least of legal age in your respective jurisdiction. The Service
                  is strictly for your personal, non-commercial useunless you enter into a separate agreement with us for
                  your commercial use. You must not use the Service if we have terminated any account of yours orbanned
                  you from use of the Platform or the Service.</span>
                <br>
                <span class="desc-title mb-4">License</span>
                <span class="desc-text">You must only use the Service as expressly permitted by these Terms. All rights not expressly granted to you
                  are reserved by coinviewingx, its third-partyproviders and other respective owners, if any.
                  <br><br>
                  coinviewingx provides content through the Service that is copyrighted and/or trademarked work of
                  coinviewingx or coinviewingx&#39;s third-party licensors and suppliers(collectively, the &#34;Content&#34;).
                  For clarity, Content shall include all such content accessed by you at any time. You acknowledge that the
                  Service has beendeveloped, compiled, prepared, revised, selected and arranged by coinviewingx and such
                  third parties through the application of methods and standards ofjudgment developed and applied through
                  the expenditure of substantial time, effort and money and constitutes valuable intellectual property of
                  coinviewingx andsuch others. Accordingly, you shall protect the proprietary rights of coinviewingx and all
                  others having rights in the Service during and after the term of theseTerms and comply with all written
                  requests made by coinviewingx to protect its and others&#39; contractual, statutory, and common law rights in the
                  Service.
                  <br><br>
                  Subject to these Terms, and your compliance with these Terms, coinviewingx hereby grants you
                  a limited, personal, non-exclusive, non-sub-licensable andnon-transferable license to use the Content
                  and to use this Service, in each case solely for your personal use. You agree not to use the Service or any of
                  theContent for any commercial purpose. Except for the foregoing license, you have no other rights to the
                  Service or any Content, and you may not modify, edit,copy, distribute, reproduce, publish, display,
                  perform, license, sell, rent, lease, loan, create derivative works of, create any index, reverse engineer,
                  alter,enhance, provide access to or in any way exploit the Service or the Content in any manner.
                  <br><br>
                  If you breach any of these Terms, the above license will terminate automatically.</span>
                <br>
                <span class="desc-title mb-4">Prohibited Activities</span><span class="desc-text">You agree that you will not:
                  <br>- Copy, modify or create derivative works of the Service or any Content;
                  <br>- Copy, manipulate or aggregate any Content (including data) for the purpose of making it available to any third party;
                  <br>- Trade, sell, rent, loan, lease or license any Content or
                  access to the Service, whether commercially or free of charge;
                  <br>- Make excessive requests for information or take any action that interferes with, disrupts or imposes an undue burden on the Service or any server ornetwork
                  connected to the Service or negatively affects the quality or availability of any Content, or speed or
                  functionality of the Service;
                  <br> - Introduce a virus, Trojan horse, worm, time bomb or other malware to the
                  Service or the Platform, or use any device, software or routine to bypass anysoftware or hardware that
                  prohibits volume requests for information;
                  <br>- Violate, bypass or circumvent any security measure intended to limit or prevent access to the Platform, Content or Service;
                  <br>- Restrict, inhibit or interfere with use of the Service by any other user (including by hacking or defacing the Platform);
                  <br>- Introduce or otherwise distribute through the Platform any computer program that damages, interferes with, intercepts, collects,
                  releases or discloses anysystem, data or personal information of ours or any third party;
                  <br>- Make use of any of our trademarks, service marks, trade names or logos or those of any third party displayed on the
                  Platform;
                  <br>- Modify or remove any copyright or other proprietary notice in the Content;
                  <br>- Use the Website, Content or Service for or in connection with any activity that (i) violates any applicable law,
                  statute, ordinance or regulation, includingwithout limitation, the U.S. Department of the Treasury&#39;s
                  Office of Foreign  Control (OFAC), or (ii) involves proceeds of any unlawful or illegal activity.
                  <br><br>
                  Additionally, you acknowledge and agree that you (and not coinviewingx) are solely responsible for obtaining and maintaining all
                  telecommunications, broadband,and computer hardware, equipment, software and services needed for you to
                  access and use the Service, and paying all charges related thereto.</span>
                <br>
                <span class="desc-title mb-4">Ownership and Intellectual Property</span><span class="desc-text">Subject to these Terms, you may view, print and make copies of
                  Content for your own personal use. You shall not, copy, reproduce, download, &#34;screen scrape&#34;,store,
                  transmit, broadcast, publish, modify, create a derivative work from, display, perform, distribute, redistribute,
                  sell, license, rent, lease or otherwise use,transfer (either in printed, electronic or other format) or
                  exploit any Content, in whole or in part, in any way that does not comply with these Terms withoutour
                  prior written permission. To request permission to use any Content other than as expressly permitted in these
                  Terms, please <a href="mailto:coinviewingx@gmail.com">coinviewingx@gmail.com</a>.
                  <br><br>
                  As between coinviewingx and you, all Content is owned or
                  controlled by coinviewingx. coinviewingx, its logo, and, except as noted below, all other product or servicenames
                  or slogans displayed on the Service are registered and/or common law trademarks of coinviewingx or its suppliers or
                  licensors and may not be copied,imitated, or used, in whole or in part, without the prior written
                  permission of coinviewingx or the applicable trademark holder. In addition, the look and feel of theService,
                  including all page headers, custom graphics, button icons, and scripts, is the service mark, trademark, and/or
                  trade dress of coinviewingx and may not becopied, imitated, or used, in whole or in part, without the prior
                  written permission of coinviewingx. All other trademarks, registered trademarks, product names,and company
                  names or logos mentioned in the Service are the property of their respective owners. Reference to any products,
                  services, processes, or otherinformation, by trade name, trademark, manufacturer, supplier, or otherwise
                  does not constitute or imply endorsement, sponsorship, or recommendationthereof by coinviewingx.
                  <br><br>
                  Nothing contained in these Terms grants you any interest in any of coinviewingx&#39;s or any third-party&#39;s intellectual
                  property.
                  <br><br>
                  The use or misuse of coinviewingx&#39;s trademarks or other intellectual property, except as
                  expressly permitted by these Terms, is prohibited. You shall promptlynotify coinviewingx at
                   <a href="mailto:coinviewingx@gmail.com">coinviewingx@gmail.com</a> if you know or suspect that any of coinviewingx&#39;s or its providers&#39; intellectual
                  property rights has been violated orinfringed.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-sdr mt-5">
      <div class="container">
        <div class="row">
          <div class="site-desc text-center pt-5">
            <h2><span>Live ETH, XRP, LTC to USDT</span> prices,<br>
              price charts, and market cap</h2>
            <p class="mt-5">Find out the real price of ETH, XRP, LTC in USDT by monitoring cryptocurrency exchanges from coinviewingx</p>
            <img src="/img/footer.svg" class="mt-5">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: 'Rules',
  components: {}
};
</script>