<template>
  <div>

    <section class="sec-def pt-5">
      <div class="container">
        <div class="row">
          <div class="section-title text-center">
            <h1>About <span>Us</span>
            </h1>
          </div>
        </div>
        <div class="article mt-4">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1">
              <p class="description-text">
                <span class="desc-text">Welcome to the best cryptocurrency exchange monitoring service for XRP, ETH, and LTC – your one-stop solution to stay informed about the dynamic world of cryptocurrency trading. Our platform is designed to provide you with real-time data, in-depth analysis, and user-friendly tools so you can make informed decisions in the rapidly changing cryptocurrency market.</span>
                <span class="desc-title">Real-Time Data </span>
                <span class="desc-text">Stay up-to-date on the market with real-time updated price data for XRP, ETH, and LTC from a wide range of cryptocurrency exchanges. Our service ensures you never miss a beat by providing you with the latest price movements, volume changes, and market trends.</span>
                <span class="desc-title">Comprehensive Exchange Coverage </span>
                <span class="desc-text">Our monitoring service covers all major cryptocurrency exchanges trading XRP, ETH, and LTC. This extensive coverage allows you to compare prices, commissions, and trading volumes on different exchanges, ensuring you find the best opportunities to buy or sell these cryptocurrencies.</span>
                <span class="desc-title">Customizable Alerts </span>
                <span class="desc-text">Set up personalized alerts to be notified of significant market movements, price changes, or specific trading conditions. Whether you're a casual trader or a professional investor, our alert system allows you to stay informed and act quickly.</span>
                <span class="desc-title">Historical Data and Analysis </span>
                <span class="desc-text">Get access to detailed historical data and comprehensive analysis tools to keep track of your trades for XRP, ETH, and LTC.</span>
                <span class="desc-title">User-Friendly Interface </span>
                <span class="desc-text">Our platform boasts an intuitive and user-friendly interface, making it easy for traders of all
                  experience levels to navigate and utilize our services effectively. Customize your dashboard to display
                  the information most relevant to you.</span>
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2">
              <div class="d-flex h-100 align-items-center justify-content-center justify-content-lg-end py-4">
                <img src="/img/about.png" class="hero-nb">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-sdr mt-5">
      <div class="container">
        <div class="row">
          <div class="site-desc text-center pt-5">
            <h2><span>Live ETH, XRP, LTC to USDT</span> prices,<br>
              price charts, and market value</h2>
            <p class="mt-5">Find out the real price of ETH, XRP, LTC in USDT by monitoring cryptocurrency exchanges from coinviewingx</p>
            <img src="/img/footer.svg" class="mt-5">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  name: 'About',
  components: {}
};
</script>